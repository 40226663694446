import React from "react"

import {
  Delimiter,
  MainTitle,
  PhotoStyled,
  Text,
  SubTitle,
  VideoContainer,
} from "../Catalog/PageCatalog.styled"
import Product from "../../components/Product/Product"
import Feedback from "../../components/Feedback/Feedback"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../layouts/MainLayout/Layout"
import Header from "../../components/Header/Header"
import Wrapper from "../../components/Wrapper/Wrapper"
import Seo from "../../config-components/seo"
import Footer from "../../components/Footer/Footer"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import productsData from "../../data/products";

const Modules = ({ location, pageContext }) => {
  const query = useStaticQuery(graphql`
    query ModulesQuery {
      products: allFile(
        filter: {
          relativeDirectory: { regex: "/images/catalog/modules/products/" }
        }
      ) {
        edges {
          node {
            publicURL
            relativeDirectory
          }
        }
      }
      photos: allFile(
        filter: {
          relativeDirectory: { regex: "/images/catalog/modules/photos/" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      docs: allFile(filter: { relativeDirectory: { regex: "/docs/" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const getProduct = (name) =>
    query.products.edges
      .filter(
        (el) => el.node.relativeDirectory.split("/").slice(-2)[0] === name
      )
      .map((el) => ({
        url: el.node.publicURL,
        alt: el.node.relativeDirectory.split("/").slice(-1)[0],
      }))

  const getPhoto = (name) =>
    query.photos.edges.filter((el) => el.node.name === name)[0].node.publicURL

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const queryDoc = (name) =>
    query.docs.edges.filter((el) => el.node.name === name)[0].node.publicURL
  const characteristics = queryDoc("modules-techs")

  const {moduleE75h, moduleE75c, moduleE75k} = productsData

  return (
    <Layout>
      <Header page={location.pathname} />
      <Wrapper width={754}>
        <div style={{ width: "100%" }}>
          <Breadcrumb
            crumbs={crumbs}
            crumbLabel="Водный батут"
            crumbSeparator=" > "
          />
        </div>
        <Seo
          title={
            "Поплавок для катамарана пластиковый, баллон для катамарана полиэтиленовый от производителя"
          }
          description={
            "Купить пластиковые поплавки, баллоны для катамаранов, тримаранов и плавающих конструкций от компании MARINA-M"
          }
          keywords={
            "купить пластиковый поплавок для катамарана, купить модули плавучести, баллоны для катамарана"
          }
        />
        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 32,
            fontWeight: "bold",
            paddingTop: 16,
            fontSize: "28px",
          }}
        >
          <h1>Модули плавучести</h1>
        </MainTitle>
        <a
          href={getPhoto("modules-1")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PhotoStyled
            src={getPhoto("modules-1")}
            style={{ maxWidth: 500, paddingBottom: 20, paddingTop: 20 }}
          />
        </a>
        <Text>
          <p>
            Пластиковые модули плавучести изготавливаются методом ротационного
            формования из полиэтилена специальных марок (
            <a
              href={"https://rotomo.ru/"}
              style={{ color: "inherit" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              rotomo.ru
            </a>
            ).
          </p>
          <p>
            Готовые изделия абсолютно герметичны, обладают хорошей прочностью,
            устойчивы к температурным колебаниям и ультрафиолету, имеют
            несколько цветовых решений.
          </p>
          <p>
            Конструкции модулей предусматривает оригинальное торцевое соединение
            изделий между собой и пазы в верхней части модуля под ложементы рамы{" "}
          </p>
        </Text>
        <a
          href={getPhoto("modules-2")}
          target="_blank"
          style={{ maxWidth: 500 }}
          rel="noopener noreferrer"
        >
          <PhotoStyled src={getPhoto("modules-2")} />
        </a>
        <Text>
          <p>
            В качестве ложементов рамы могут быть использованы прямоугольные
            профили 60*40 или 80*40 из нержавеющих марок стали, алюминия,
            оцинкованного металла или бруса из дерева.
          </p>
          <p>
            Модули плавучести могут быть использованы для строительства
            катамаранов, тримаранов, понтонных лодок, плавучих платформ
            различного назначения, как самоходных, так и стационарных.
          </p>
        </Text>

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Модуль плавучести Е-75Н (носовой)</h2>
        </MainTitle>
        <Product
          info={moduleE75h.mainInfo}
          price={moduleE75h.price}
          images={getProduct("module-e75h")}
          style={{ marginTop: 0, marginBottom: 0 }}
        />
        <Text style={{ marginTop: 15 }}>
          <p>
            Модуль Е-75Н имеет обтекаемую носовую часть. Предназначен для
            установки начальным или конечным элементом ряда модулей плавучего
            сооружения.
          </p>
        </Text>

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Модуль плавучести Е-75С (средний)</h2>
        </MainTitle>
        <Product
          images={getProduct("module-e75c")}
          info={moduleE75c.mainInfo}
          price={moduleE75c.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Модуль плавучести Е-75К (кормовой)</h2>
        </MainTitle>
        <Product
          images={getProduct("module-e75k")}
          info={moduleE75k.mainInfo}
          price={moduleE75k.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />
        <Text style={{ marginTop: 20 }}>
          <p>
            Модуль плавучести Е-75К (кормовой) предназначен для установки
            кормовым модулем для самоходных катамаранов, тримаранов и понтонных
            лодок.
          </p>
          <p>
            В кормовой части модуля предусмотрено специальное место для монтажа
            электродвигателя.
          </p>
        </Text>

        <Delimiter style={{ paddingTop: 0 }} />

        <a
          href={characteristics}
          target="_blank"
          style={{ color: "#5a6578" }}
          rel="noopener noreferrer"
        >
          Скачать технические характеристики модулей (pdf)
        </a>

        <Delimiter style={{ paddingTop: 0 }} />

        <SubTitle>Видеообзор модулей плавучести</SubTitle>
        <VideoContainer>
          <iframe
            allowFullScreen="allowfullscreen"
            src="https://www.youtube.com/embed/Avyw4qNe9Z4"
            frameBorder="0"
          />
        </VideoContainer>
        <br />
        <VideoContainer>
          <iframe
            allowFullScreen="allowfullscreen"
            src="https://www.youtube.com/embed/vHVAVduqdqk"
            frameBorder="0"
          />
        </VideoContainer>

        <Delimiter />

        <div id={`feedback`}>
          <Feedback styles={{ marginBottom: "2rem", marginTop: "2rem" }} />
        </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default Modules
